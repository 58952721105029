import * as yup from 'yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/system/';

export const { registerMessageResponseHandler, REGISTER_MESSAGE_RESPONSE_HANDLER }: any = createTypedAction(
  `${PREFIX}REGISTER_MESSAGE_RESPONSE_HANDLER`,
  ['messageId', yup.string().required()],
  ['successHandlers', yup.array()],
  ['failureHandlers', yup.array()],
  ['timeout', yup.number()]
);

export const { addRecentFrogResponse, ADD_RECENT_FROG_RESPONSE }: any = createTypedAction(
  `${PREFIX}ADD_RECENT_FROG_RESPONSE`,
  ['messageId', yup.string().required()],
  ['data', yup.object().required()]
);

export const { removeMessageFromHandlers, REMOVE_MESSAGE_FROM_HANDLERS }: any = createTypedAction(
  `${PREFIX}REMOVE_MESSAGE_FROM_HANDLERS`,
  ['messageId', yup.string().required()]
);
