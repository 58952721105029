import 'src/screen/Protected/Wizards/Wizards.scss';
import variables from 'src/style/variable/variables.module.scss';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map, List } from 'immutable';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import CancelIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircleOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import DeviceSelectorField from 'src/component/UI/DeviceSelectorField';
import Button from 'src/component/UI/Button';
import { snapshotDeviceSettings, clearDeviceSettingsSnapshot, upgradeDevice, fetchDeviceInfo, fetchCommLoops } from 'src/module/device/action';
import { getDeviceSettingsSnapshotStatus, getApplySnapshotStatus, getDeviceById, getCommLoops } from 'src/module/device/selector';
import LoadingSpinner from 'src/component/UI/LoadingSpinner';
import CircularWithValueLabel from 'src/component/UI/CircularProgressWithLabel';
import { isWhitelabel } from 'src/utils/utils';
import { getPossibleUpgradePaths } from 'src/module/upgrade/selector';
import { getLabelForDeviceModel } from 'src/utils/langLookUp';
import { clearDeviceUpgradeLoading, fetchDeviceUpgrades } from 'src/module/upgrade/action';
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';

export default function UpgradeDeviceScreen () {
  const dispatch = useDispatch();
  const snapshotStatus: any = useSelector(getDeviceSettingsSnapshotStatus);
  const reprogramStatus: any = useSelector(getApplySnapshotStatus);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDeviceFactory, setSelectedDeviceFactory] = useState('');
  const [replacementDeviceFactory, setReplacementDeviceFactory] = useState('');
  const [deviceCommLoop, setDeviceCommLoop] = useState(null);
  const [snapshotStarted, setSnapshotStarted] = useState(false);
  const [reprogramStarted, setReprogramStarted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const comms = useSelector(getCommLoops);
  const commLoops = [{ label: 'None', value: 'null' }];
  comms?.forEach((loop: any) => {
    const id = loop?.get('id');
    const label = loop?.get('tag');
    commLoops.push({ label, value: id });
  });

  let potentialUpgradeDevices: any = useSelector(getPossibleUpgradePaths(selectedDeviceFactory));
  const selectedDeviceData: any = useSelector(getDeviceById(selectedDevice?.id));
  if (selectedDevice && selectedDeviceFactory && !potentialUpgradeDevices.find((deviceFactory => deviceFactory === selectedDeviceFactory))) {
    potentialUpgradeDevices = potentialUpgradeDevices.concat(selectedDeviceFactory);
  }
  const [deviceAddress, setDeviceAddress] = useState('');

  useEffect(() => {
    if (selectedDeviceData && !selectedDeviceData.isEmpty()) {
      setSelectedDeviceFactory(selectedDeviceData.get('device_factory', ''));
    }
    else if (selectedDevice && (!selectedDeviceData || selectedDeviceData.isEmpty())) {
      dispatch(fetchDeviceInfo(selectedDevice?.id));
    }
    else {
      setSelectedDeviceFactory('');
    }
  }, [selectedDeviceData, selectedDevice]);

  useEffect(() => {
    dispatch(fetchDeviceUpgrades());
    dispatch(clearDeviceUpgradeLoading());
    dispatch(fetchCommLoops());
  }, []);

  const [sectionCollapsed, setSectionCollapsed] = useState(Map({
    step1: false,
    step2: true,
    step3: true
  }));

  const [completedSteps, setCompletedSteps] = useState(Map({
    step1: false,
    step2: false,
    step3: false
  }));

  useEffect(() => {
    dispatch(clearDeviceSettingsSnapshot());
  }, []);

  const makeToggleSection = (section) => {
    return () => {
      toggleSectionCollapsed(section);
    };
  };

  const handleChange = (value) => {
    dispatch(clearDeviceSettingsSnapshot());
    setSnapshotStarted(false);

    if ((value === null || value === '' || value.id === '')) {
      setSelectedDevice(null);
    } else {
      setSelectedDevice(value);
    }
  };

  const toggleSectionCollapsed = (section: string) => {
    setSectionCollapsed(sectionCollapsed.set(section, !sectionCollapsed.get(section)));
  };

  const toggleCompletedStep = (step: string) => {
    setCompletedSteps(completedSteps.set(step, !completedSteps.get(step)));
  };

  const handleStep1Submit = () => {
    toggleCompletedStep('step1');
    setSectionCollapsed(Map({ step1: true, step2: false, step3: true }));
  };

  const handleStep2Submit = () => {
    toggleCompletedStep('step2');
    setSectionCollapsed(Map({ step1: true, step2: true, step3: false }));
  };

  const handleStep3Submit = () => {
    setReprogramStarted(true);
    dispatch(upgradeDevice(selectedDevice?.id, replacementDeviceFactory, deviceCommLoop, deviceAddress));
  };

  const snapshot = () => {
    setSnapshotStarted(true);
    dispatch(snapshotDeviceSettings(selectedDevice.id));
  };

  const snapshotDevices: List<any> = snapshotStatus.get('snapshotDevices', List());
  const numDevices: number = snapshotStatus.get('numDevices');
  const progress: number = snapshotStatus.get('progress');

  const devicesToReprogram: List<any> = reprogramStatus.get('snapshotDevices', List());
  const numDevicesToReprogram: number = reprogramStatus.get('numDevices', 0);
  const reprogramProgress: number = reprogramStatus.get('progress');

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  const handlereplacementDeviceFactory = (factory) => {
    setReplacementDeviceFactory(factory);
  };

  const handleCheckboxChange = () => {
    if (isChecked) {
      setDeviceCommLoop(null);
      setDeviceAddress('');
    }
    setIsChecked(!isChecked);
  };

  const handleCommLoopChange = (event) => {
    setDeviceCommLoop(event?.target?.value ?? null);
  };
  const status = (device) => {
    if (device.get('failed')) {
      return (<Tooltip title='Unable to communicate with device. No settings available for device'>
        <div className='device-status-loaded error-status'><CancelIcon width={20} /> </div>
      </Tooltip>);
    }
    if (device.get('warning')) {
      return (<Tooltip title='Unable to communicate with device. Using last fetched settings.'>
        <div className='device-status-loaded complete-with-error '><ErrorIcon width={20} /> </div>
      </Tooltip>);
    }
    return (<div className='device-status-loaded complete'>
      <CheckCircleOutlinedIcon width={20} />
    </div>);
  };
  const overAllStatus = (snapshotStarted, snapshotDevices) => {

    if (snapshotStarted) {
      if (snapshotDevices.some(x => x.get('failed'))) {
        return   <div className='error-status'><CancelIcon width={18} /> </div>;
      }
      return <CircularWithValueLabel size={28} interval={20} hideLabel value={progress * 100 || 1} />;
    }
    return <CircleOutlinedIcon />;
  };

  const deviceStatus = (device) => {
    if (device.get('done')) {
      return <div className='device-status-loaded complete'>
        <CheckCircleOutlinedIcon width={20} />
      </div>;
    }
    if (reprogramStatus.get('status') === 'failed') {
      return <Tooltip title='Unable to communicate with device. Device was partially upgraded.'>
        <div className='device-status-loaded complete-with-error '><ErrorIcon width={20} /> </div>
      </Tooltip>;
    }
    return  <div className='device-status-loaded'>
      <LoadingSpinner color={variables.greyDark } />
    </div>;
  };
  const step3Status = () => {
    if (reprogramStarted) {
      if (reprogramStatus.get('status') === 'failed') {
        return <ErrorIcon className='complete-with-error' width={28} />;
      }
      return <CircularWithValueLabel size={28} interval={50} hideLabel value={reprogramProgress * 100} />;
    }

    return <CircleOutlinedIcon />;
  };
  return (
    <div id='upgrade-device' className='guide-wizard page'>
      <Helmet>
        <title>{title} - Upgrade/Replace a Device</title>
      </Helmet>

      <Grid item xs={12}>
        <h2>Upgrade/Replace a Device</h2>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('step1') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('step1')}>
              <div >
                {overAllStatus(snapshotStarted, snapshotDevices)}
                <span>Step 1 - Choose Device to Replace</span>
              </div>
              <span className='expand-arrow'>{sectionCollapsed.get('step1') ? '▲' : '▼'}</span>
            </h2>
            {!sectionCollapsed.get('step1') ? <div className='inner-section'>
              <div className='select-device-bar'>
                <DeviceSelectorField onChange={handleChange} hideLabel value={selectedDevice} type='controller' />
                <Button disabled={selectedDevice === null} onClick={snapshot} cta>Pull Latest Settings</Button>
              </div>

              {numDevices > 1 ? (<>
                <h4>Gathering Settings from Devices...</h4>
                <div className='device-status-container'>
                  {snapshotDevices?.map((device, idx) => {
                    return (
                      <div className='device-status' key={`${device.get('deviceId')}-${idx}-status`}>
                        {device.get('done') ? (
                          <>
                            {status(device)}
                          </>

                        ) : (
                          <div className='device-status-loaded'>
                            <LoadingSpinner color={variables.greyDark } />
                          </div>
                        )}
                        <div className='device-status-tag'>{device.get('tag')}</div>
                      </div>
                    );
                  })}
                </div>
              </>) : null}
              <div className='button-bar'>
                <Button disabled={!selectedDevice || progress !== 1} onClick={handleStep1Submit} cta>Next</Button>
              </div>
            </div> : null}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('step2') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('step2')}>
              <div>
                {completedSteps.get('step2') ? (<CheckCircleOutlinedIcon className='complete' />) : (<CircleOutlinedIcon />)}
                <span>Step 2 - Change the Hardware</span>
              </div>
              <span className='expand-arrow'>{sectionCollapsed.get('step2') ? '▲' : '▼'}</span>
            </h2>
            {!sectionCollapsed.get('step2') ? <div className='inner-section'>
              <p>Now it&apos;s time to replace the physical hardware. Disconnect the old device, and install the new device. Make sure the new device has the same Modbus communication details as the old device.</p>
              <h3>Select your replacement device</h3>
              <div className="squares-container">
                {potentialUpgradeDevices.toJS().map((factory, index) => (
                  <div key={`potential-upgrade-${index}`} className="square" onClick={() => handlereplacementDeviceFactory(factory)}>
                    <Paper className={`squares-paper${replacementDeviceFactory === factory ? ' selected-device' : ''}`}>
                      <div style={{ fontSize: '1.2em', marginBottom: '5px' }}>{getLabelForDeviceModel(factory.replace('Controller', ''))}</div>
                      {factory.replace('Controller', '') === selectedDeviceFactory.replace('Controller', '') && (
                        <div style={{ fontSize: '0.8em', textAlign: 'center' }}>Same Model</div>
                      )}
                    </Paper>
                  </div>
                ))}
              </div>
              <div className='button-bar'>
                <Button onClick={handleStep2Submit} disabled={!replacementDeviceFactory} cta>Next</Button>
              </div>
            </div> : null}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('step3') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('step3')}>
              <div>
                {step3Status()}
                <span>Step 3 - Reprogram New Device</span>
              </div>
              <span className='expand-arrow'>{sectionCollapsed.get('step3') ? '▲' : '▼'}</span>
            </h2>
            {!sectionCollapsed.get('step3') ? <div className='inner-section'>
              {numDevicesToReprogram > 1 ? (<>
                <h4>Reprogramming devices...</h4>
                <div className='device-status-container'>
                  {devicesToReprogram?.map((device) => {
                    return (
                      <div className='device-status' key={device.get('deviceId')}>
                        {deviceStatus(device)}

                        <div className='device-status-tag'>{device.get('tag')}</div>

                      </div>
                    );
                  })}
                </div>
              </>) : <>
                {
                  <div className='commloop-container'>
                    <label>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      I Need to Change the Comms Data of This Device
                    </label>
                    {isChecked && (
                      <FormControl fullWidth>
                        <InputLabel id="comms-select-label">Comm Loop</InputLabel>
                        <Select
                          labelId="comms-select-label"
                          value={deviceCommLoop ?? ''}
                          onChange={handleCommLoopChange}
                        >
                          {commLoops.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          fullWidth
                          id="deviceAddress"
                          name="deviceAddress"
                          label="Address"
                          type="number"
                          value={deviceAddress}
                          onChange={(event) => { setDeviceAddress(event?.target?.value); }}
                          variant='outlined'
                          InputLabelProps={{ shrink: false }}
                        />
                      </FormControl>
                    )}
                  </div>

                }
              </>}
              <div className='button-bar'>
                <Button disabled={!selectedDevice || reprogramStarted} onClick={handleStep3Submit} cta>Reprogram New Device</Button>
              </div>
            </div> : null}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
