import React, { useState, useEffect, useMemo, useCallback } from 'react';

import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';

import { useSelector, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';

import { fetchIntegrations } from 'src/module/integration/action';
import { getIntegrations } from 'src/module/integration/selector';
import { fetchControlPanelTags, clearControlPanelTags, setSettingsModalClose, fetchCommLoopTags, setSettingsModalOpen, fetchPanelByQrCode } from 'src/module/device/action';
import { getControlPanelTags, getSettingsModal, getCommLoopTags, getShowSynchronizingDevicesPopUp, getPanelDataFromDirectory } from 'src/module/device/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { addController } from 'src/module/controller/action';
import InitDeviceModel from 'src/screen/Protected/DeviceScreen/InitDeviceModel';
import { getFeatures } from 'src/module/authentication/selector';
import { useParams } from 'react-router-dom';
import { pushHistory } from 'src/module/navigation/action';

function AddControllerModal () {
  const dispatch = useDispatch();
  const { newPanelId } = useParams();
  const integrations = useSelector(getIntegrations, (prev, next) => prev.equals(next));
  const commLoopTags = useSelector(getCommLoopTags, (prev, next) => prev.equals(next));
  const settingsModal = useSelector(getSettingsModal(null), (prev, next) => prev.equals(next));
  const [panelId, setPanelId] = useState(null);
  const [filledValuesObj, setFilledValuesObj] = useState(newPanelId ? { device_factory: 'SmartTraceController' }: {});
  const fieldValuesObj = {};
  const showSyncPopUp = useSelector(getShowSynchronizingDevicesPopUp);
  const features = useSelector(getFeatures, (prev, next) => prev.equals(next));
  const panelDataFromDirectory = useSelector(getPanelDataFromDirectory(newPanelId));
  if (newPanelId) {
    dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_CONTROLLER));
    dispatch(fetchPanelByQrCode(newPanelId));
  }

  useEffect(() => {
    dispatch(clearControlPanelTags());
    dispatch(fetchIntegrations());
    dispatch(fetchCommLoopTags());
    dispatch(fetchControlPanelTags());
    dispatch(setSettingsModalClose(null));
  }, []);

  const frogClients = useMemo(() => {
    const clients = [];
    integrations?.forEach(integration => {
      if (integration.get('type') === 'frog') {
        const frogId = integration?.get('id');
        const frogName = integration?.getIn(['settings', 'name']) || frogId.substring(0, 5);
        clients.push({ label: frogName, value: frogId });
      }
    });
    return clients;
  }, [integrations]);

  const commonControllerFields = useMemo(() => {
    // Basic controller options
    const controllerOptions = [
      { label: 'NVent 910', value: 'NVent910' },
      { label: 'NVent 920', value: 'NVent920' },
      { label: 'NVent NGC30', value: 'NVentNGC30Controller' },
      { label: 'NVent NGC40', value: 'NVentNGC40Controller' },
      { label: 'NVent AC2000+', value: 'NVentAC2000P' },
      { label: 'Thermon TCM2', value: 'ThermonTCM2Controller' },
      { label: 'Thermon TCM18', value: 'ThermonTCM18Controller' },
      { label: 'Thermon TC101', value: 'ThermonTC101Controller' },
      { label: 'Thermon TC201', value: 'ThermonTC201Controller' },
      { label: 'Thermon TC202', value: 'ThermonTC202Controller' },
      { label: 'Thermon TC1818', value: 'ThermonTC1818Controller' },
      { label: 'Chromalox ITLSC1D2', value: 'ChromaloxITLSC1D2EXTController' },
    ];

    // Conditionally add beta-specific options if `features` includes 'beta'
    if (features.includes('beta')) {
      controllerOptions.push(
        { label: 'ATCOM+', value: 'ATCOMPlusController' },
        { label: 'Thermon Genesis', value: 'ThermonGenesisController' },
        { label: 'MasterTrace MR100', value: 'MasterTraceMR100Controller' },
        { label: 'MSCP Modular EHT Controller', value: 'MSCPController' },
        { label: 'SmartTrace Controller', value: 'SmartTraceV2Controller' },
        { label: 'SmartTrace Monitored Panel', value: 'SmartTraceMPController' }
      );
    }

    const commLoopOptions = [{ label: 'Add Comm Loop', value: 'null' }];
    commLoopTags?.forEach(commLoop => {
      commLoopOptions.push({ label: commLoop.get('tag'), value: commLoop.get('id') });
    });

    return {
      device_factory: {
        type: 'select',
        label: 'Device Type',
        options: controllerOptions,
        errorText: 'Required',
        binding: true,
        order: 10
      },
      parent_id: {
        type: 'select',
        label: 'Comm Loop',
        options: commLoopOptions,
        errorText: 'Required',
        order: 30
      },
      tag: {
        type: 'text',
        label: 'Controller Tag',
        errorText: 'Required',
        order: 70
      },
      address: {
        type: 'number',
        label: 'Controller Address',
        errorText: 'Required',
        order: 80
      }
    };
  }, [features, commLoopTags]);

  const [addControllerFields, setAddControllerFields] = useState(commonControllerFields);

  const onValueChange = useCallback((panel) => {
    setPanelId(panel.id);
  }, []);

  const handleChange = (name, value) => {
    fieldValuesObj[name] = value;
    if (name === 'parent_id' && value === 'null') {
      setAddControllerFields(Object.assign({}, addControllerFields, {
        frog_client_id: {
          type: 'select',
          label: 'Assigned Supervisor Client',
          options: frogClients,
          errorText: 'Required',
          order: 40
        },
        ip_address: {
          type: 'text',
          label: 'IP address',
          errorText: 'Required',
          order: 50
        },
        port: {
          type: 'number',
          label: 'Port',
          errorText: 'Required',
          order: 60
        },
      }));
    }
    else if (name === 'parent_id' && value !== 'null') {
      delete addControllerFields['ip_address'];
      delete addControllerFields['port'];
      delete addControllerFields['frog_client_id'];
      delete filledValuesObj['ip_address'];
      delete filledValuesObj['port'];
      delete filledValuesObj['frog_client_id'];
      setAddControllerFields(Object.assign({}, addControllerFields, {}));
    }
    if (name === 'device_factory' && (value === 'NVent920' || value === 'NVent910')) {
      (addControllerFields as any).subaddress = {
        type: 'number',
        label: 'Subaddress',
        errorText: 'Required',
        order: 90
      };
      setAddControllerFields(addControllerFields);
    }
    else if (name === 'device_factory') {
      delete addControllerFields['subaddress'];
    }

    if (name === 'device_factory' && value === 'NVentNGC40Controller') {
      delete addControllerFields['control_panel_id'];
    }
    else if (name === 'device_factory' && value !== 'NVentNGC40Controller') {
      (addControllerFields as any).control_panel_id = {
        type: 'autoCompleteSelect',
        label: 'Panel',
        options: [],
        deviceType: 'panel',
        optionsSelector: getControlPanelTags,
        freeSolo: true,
        formatOption: (option) => ({
          label: option.get('tag'),
          value: option.get('id')
        }),
        onValueChange,
        order: 20,
      };

      setAddControllerFields(addControllerFields);
    }
    setFilledValuesObj(Object.assign({}, filledValuesObj, fieldValuesObj));
  };

  useEffect(() => {
    if (!settingsModal?.get('isOpen')) {
      setAddControllerFields(commonControllerFields);
      setFilledValuesObj({});
    } else {
      if (newPanelId && panelDataFromDirectory) {
        setFilledValuesObj({ device_factory: panelDataFromDirectory?.get('device_factory') });
      }
    }
  }, [settingsModal, commonControllerFields, dispatch]);

  const handleClose = useCallback(() => {
    setAddControllerFields(commonControllerFields);
    setFilledValuesObj({});
    dispatch(setSettingsModalClose(null));
    if (newPanelId) {
      dispatch(pushHistory('/devices/list'));
    }
  }, [commonControllerFields, dispatch]);

  const addControllerModalSubmit = useCallback((rec) => {
    const address = parseInt(rec.get('address'), 10);
    let subaddress = rec.get('subaddress', null);
    if (subaddress) {
      subaddress = parseInt(subaddress, 10);
    }
    let port = rec.get('port', null);
    if (port) {
      port = parseInt(port, 10);
    }
    const parentId = rec.get('parent_id') === 'null' ? null : rec.get('parent_id');
    rec = rec.set('address', address);
    rec = rec.set('subaddress', subaddress);
    rec = rec.set('port', port);
    if (panelId?.includes('new-user-entry~')) {
      rec = rec.set('new_control_panel', panelId.split('~')[1]);
      rec = rec.delete('control_panel_id');

    } else {
      rec = rec.set('control_panel_id', panelId);
    }
    rec = rec.set('parent_id', parentId);
    dispatch(addController(rec));
  }, [dispatch, panelId]);

  return (
    <>
      {
        showSyncPopUp ?
          <InitDeviceModel />
          :
          null
      }

      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.ADD_CONTROLLER}
        handleClose={handleClose}
        handleSubmit={addControllerModalSubmit}
        handleChange={handleChange}
        title={'Add New Controller'}
        loading={settingsModal?.get('loading')}
        settings={fromJS(filledValuesObj)}
        fields={
          fromJS(addControllerFields)
        }
      />
    </>
  );
}

AddControllerModal.propTypes = {
};

AddControllerModal.defaultProps = {
};

export default React.memo(AddControllerModal);
